import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useCart from '@/public/hooks/use-cart';
import { formattedNumber } from '@/shared/helpers/String'
import { setOpen } from '@/public/redux/cartSlice'
import { customerSignedIn } from '@/shared/helpers/Customer'

export default function CartButton({ userCartId }) {
  const dispatch = useDispatch()
  const { cart, mutate } = useCart()
  const quantity = cart?.contents?.reduce((sum, item) => sum + item.quantity, 0)

  const handleOpen = (e) => {
    e.stopPropagation()

    if (quantity > 0) {
      dispatch(setOpen(true))
    }
  }

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      dispatch(setOpen(false))
    }
  }

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])

  useEffect(() => {
    if (customerSignedIn) {
      window.localStorage.setItem('cart', userCartId)
      mutate()
    }
  }, [userCartId])

  return (
    <button
      type="button"
      style={{ cursor: quantity > 0 ? 'pointer' : 'initial' }}
      onClick={(e) => handleOpen(e)}
    >
      <i className="fs-4 icon-cart" />

      {
        quantity > 0 ? (
          <span className="badge rounded-pill">
            {formattedNumber(quantity)}
          </span>
        ) : (
          <span className="badge rounded-pill bg-none">
            &nbsp; &nbsp;
          </span>
        )
      }
    </button>
  )
}
